import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './TestimonialSlider.scss';
import Carousel from '../../Carousel';
import GatsbyLink from '../../GatsbyLink'
import OnVisible from 'react-on-visible';
import { slugify, decodeEntities } from '../../../utils/helpers';
import { Image } from '../../Image';
import Heading from '../../Heading/Heading';
import GoogleReviews from '../../GoogleReviews';

export const TestimonialItem = props => {
  const {showLogo, slide, className} = props;
  
  return (
  
    <div className={`${className}${showLogo ? ' logo-testimonial' : ' testimonial'}`}>
      <div className="wrap">
        <div className="inner">
          <div className="content">
            {slide.content && <div className="testimonial-body" dangerouslySetInnerHTML={{ __html: slide.content }} />}
            {slide.acf.name && <span className="name">{decodeEntities(slide.acf.name) }</span>}
            {slide.acf.position && <span className="position">{decodeEntities(slide.acf.position)}</span>}
            {slide.acf.date && <span className="date">{decodeEntities(slide.acf.date)}</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

class ThisSlider extends Component {
  render () {
    const { testimonials, title } = this.props
    const settings = {
      container: `${slugify(title).substring(0,9)}-slug`,
      nav: false,
      controls:true,
      mouseDrag: true,
      items: 3,
      loop: true,
      responsive: {
        0: {
          items: 1
        },
        800: {
          items: 2
        },
        1080: {
          items: 3
        }
      }
    };
    return (
      <div className="carousel-wrap">
        <Carousel settings={settings}>
          {testimonials.map((slide, slideCount) => slideCount < 6 &&
            <TestimonialItem key={slideCount} slide={slide} className="slide" />
          )}
        </Carousel>
      </div>
    )
  }
}

export const TestimonialPass = props => {
  const { title, introContent, actions, select_type_of_testimonial, data  } = props;
  const { allWordpressWpTestimonials } = data;
  //const {nodes: select_type_of_testimonial} = data.allWordPressAcfTestimonialSlider;
  //console.log(data.allWordPressAcfTestimonialSlider.nodes[5][]);
  const { nodes: posts } = allWordpressWpTestimonials;
  let googlesliderCarousel, testimonialsliderCarousel;

  // choose what testimonials are displayed (CMS testimonials and/or Google reviews)
  if (select_type_of_testimonial == "cms_testimonials") {
    testimonialsliderCarousel =<ThisSlider testimonials={posts} title={title} />;
  } else if (select_type_of_testimonial == "google_testimonials"){
    googlesliderCarousel = <GoogleReviews />;
  } else if (select_type_of_testimonial == "" || select_type_of_testimonial == "cms_and_google"){
    googlesliderCarousel = <GoogleReviews />; 
    testimonialsliderCarousel = <ThisSlider testimonials={posts} title={title} />;
  }

  return (
    <>
      <section className="testimonial-slider">
        <div className="wrap">
          {title && <Heading tag="h1" className="section-title center">{decodeEntities(title)}</Heading>}
          {introContent && <div className="intro-content" dangerouslySetInnerHTML={{__html: decodeEntities(introContent)}} />}
          {actions && actions.length > 0 && (
            <div className="actions">
              {actions.map(action => action.link && (
                <GatsbyLink key={action.link.url} to={action.link.url} className="action" decode>{action.link.title}</GatsbyLink>
              ))}
            </div>
          )}
          
          {testimonialsliderCarousel}
         
        </div>
      </section>
      {googlesliderCarousel}
    </>
  )
}

export const TestimonialSlider = (props) => {
  return (
    <StaticQuery
      // Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              slug
              title
              acf {
                name
                date
                position
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  );
}
